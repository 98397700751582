import React from 'react';
import { Routes, Route } from 'react-router-dom'
import loadable from '@loadable/component'
import PortalLayout from './containers/PortalLayout/PortalLayout';
const App = () => {
  const CaptionGenerator = loadable(() => import('./containers/Products/CaptionGenerator'))
  const TitleGenerator = loadable(() => import('./containers/Products/TitleGenerator'))
  const ListingGenerator = loadable(() => import('./containers/Products/ListingGenerator'))
  const Home = loadable(() => import('./containers/Home'))

  function Other() {
    // using window.location.href
    window.location.href = 'https://xenialabs.io/';
    return null;
  }

  return (
    <Routes>
      <Route element={<PortalLayout />}>
        <Route path="" element={<Other />} />
        <Route path="/caption-generator" element={<CaptionGenerator />} />
        <Route path="/title-generator" element={<TitleGenerator />} />
        <Route path="/listing-generator" element={<ListingGenerator />} />
        <Route path="*" element={<Other />} />
      </Route>
    </Routes>
  );
};
export default App;