import React, { useRef, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { Button, ConfigProvider, Drawer, Image, Layout, theme } from "antd";
import Lottie from "lottie-react";
import { EuroOutlined, HeartOutlined, MenuOutlined, CalculatorOutlined } from '@ant-design/icons';import "./styles.scss";
import { Header } from "antd/es/layout/layout";

import mainLogo from '../../assets/xenia-lab-logo.png';
import animation from '../../assets/lottie.json';
const { Content, Footer } = Layout;


const PortalLayout = () => {
  const { darkAlgorithm } = theme;
  const navigate = useNavigate();
  const ref = useRef(null);
  const [visible, setVisible] = useState(false)

  return (
    <ConfigProvider theme={{
      algorithm: darkAlgorithm,
    }}>
      <Layout className="layout">
        <Header
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <div className="logo-img">
            <a href="https://xenialabs.io">
              <Lottie animationData={animation} loop={true} className="animation" />;
              <img src={mainLogo} alt="main-logo" className="main-logo" />
            </a>
          </div>
          <div className="page-links">
            <a href="https://xenialabs.io/#products">Products</a>
            <a href="https://hosttempo.com">Tempo</a>
            <a href="https://xenialabs.io/#faq">FAQs</a>
          </div>
          <a href="https://xenialabs.io/#contact">
            <button className="custom-btn contact-btn">Provide Feedback</button>
          </a>
          <Button className="menu-outlined" type="primary" shape="circle" icon={<MenuOutlined />} onClick={() => setVisible(true)} />
          <Drawer title={<Image src={mainLogo} alt="logo" className="mobile-menu-logo" />} placement="right" onClose={() => setVisible(false)} visible={visible}>
            <div style={{ display: 'flex', flexDirection: "column", alignItems: 'flex-start' }}>
              <Button type="text" href="https://xenialabs.io/#products" icon={<EuroOutlined />}>Products</Button>
              <Button type="text" href="https://hosttempo.com" icon={<HeartOutlined />}>Tempo</Button>
              <Button type="text" href="https://xenialabs.io/#faq" icon={<CalculatorOutlined />}>FAQs</Button>
            </div>
          </Drawer>
        </Header>
        <Content>
          <div
            className="site-layout-content"
          >
            <Outlet />
          </div>
        </Content>
        <Footer ref={ref}>
          <span>© 2023 Tempo Labs. All rights reserved.</span>
          <div>
            <a href={'https://www.linkedin.com/company/hosttempo/'} alt="linkedin">
              <svg fill="none" viewBox="0 0 48 48"><g clip-path="url(#clip0_17_68)"><path fill="currentColor" d="M44.447 0H3.544C1.584 0 0 1.547 0 3.46V44.53C0 46.444 1.584 48 3.544 48h40.903C46.407 48 48 46.444 48 44.54V3.46C48 1.546 46.406 0 44.447 0zM14.24 40.903H7.116V17.991h7.125v22.912zM10.678 14.87a4.127 4.127 0 01-4.134-4.125 4.127 4.127 0 014.134-4.125 4.125 4.125 0 010 8.25zm30.225 26.034h-7.115V29.766c0-2.653-.047-6.075-3.704-6.075-3.703 0-4.265 2.896-4.265 5.887v11.325h-7.107V17.991h6.826v3.13h.093c.947-1.8 3.272-3.702 6.731-3.702 7.21 0 8.541 4.744 8.541 10.912v12.572z"></path></g><defs><clipPath id="clip0_17_68"><path fill="currentColor" d="M0 0h48v48H0z"></path></clipPath></defs></svg>
            </a>
          </div>
        </Footer>
      </Layout>
    </ConfigProvider>
  );
};

export default PortalLayout;
